import { Button, Menu, Popover, MenuItem, Spinner } from "@blueprintjs/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { nodeFastServerAPI } from "../../utils/agent";

export default function ConverterModule() {
    const [fileSelectorFiles, setFileSelectorFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [call, setCall] = useState<number>();
    const [convertText, setConvertText] = useState("Convert to...");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        getFilesFromOldAPI();
    }, []); 

    const auth = useSelector((state: ApplicationState) => state.auth);

    async function getFilesFromOldAPI() {
        try {
            const response = await Axios.get(`https://lbidsufc8000.asets.io/list-files-info`, {
                headers: {
                    'directory': 'all',
                    // 'user-id': 1,
                    'user-id': auth.User_id,
                }
            });
            if (response.status === 200 && response.data.files) {
                setFileSelectorFiles(response.data.files);
            }
        } catch (error) {
            console.log("Error", error);
        }
    }

    async function handleConvert() {
        try {
            if (!call || !fileName) {
                setMessage("Please select a file and a conversion type.");
                return;
            }
            setLoading(true);
            setMessage("");
    
            let url = "";
            switch (call) {
                case 1:
                    url = `https://lbidsufc8000.asets.io/convert-to-ply`;
                    break;
                case 2:
                    url = `https://lbidsufc8000.asets.io/convert-to-stl`;
                    break;
                case 3:
                    url = `https://lbidsufc8000.asets.io/convert-to-glb`;
                    break;
                case 4:
                    url = `https://lbidsufc8000.asets.io/convert-ply-to-gltf`;
                    break;
                case 5:
                    url = `https://lbidsufc8000.asets.io/convert-stl-to-gltf`;
                    break;
                case 6:
                    url = `https://lbidsufc8000.asets.io/convert-obj-to-usd`;
                    break;
                default:
                    setMessage("Invalid conversion type selected.");
                    setLoading(false);
                    return;
            }
    
            // Find the selected file details
            const selectedFile = fileSelectorFiles.find(file => file.file_name === fileName);
            if (!selectedFile) {
                setMessage("File not found.");
                setLoading(false);
                return;
            }
    
            const response = await Axios.get(url, {
                headers: {
                    'file_name': fileName,
                    // 'user-id': 1,
                    'user-id': auth.User_id,
                    'file-id': selectedFile.file_id,
                },
                responseType: 'blob'
            });
    
            if (response.status === 200) {
                setMessage("File Converted Successfully");
    
                // Extract filename from content-disposition header
                let convertedFileName = 'converted_file';
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                    if (matches != null && matches[1]) { 
                        convertedFileName = matches[1].replace(/['"]/g, '');
                    }
                }
    
                // Determine the file extension based on the conversion type
                let fileExtension = '';
                switch (call) {
                    case 1:
                        fileExtension = 'ply';
                        break;
                    case 2:
                        fileExtension = 'stl';
                        break;
                    case 3:
                        fileExtension = 'glb';
                        break;
                    case 4:
                        fileExtension = 'gltf';
                        break;
                    case 5:
                        fileExtension = 'gltf';
                        break;
                    case 6:
                        fileExtension = 'usd';
                        break;
                    default:
                        fileExtension = 'unknown';
                }
    
                // Append the correct file extension if not present
                if (!convertedFileName.endsWith(`.${fileExtension}`)) {
                    convertedFileName += `.${fileExtension}`;
                }
    
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', convertedFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                setMessage("Conversion Failed");
            }
        } catch (error) {
            console.error("Error during conversion:", error);
            setMessage("Conversion Failed");
        } finally {
            setLoading(false);
        }
    }

    const handleMenuItemClick = (format: string, callType: number) => {
        setConvertText(`Convert to ${format}`);
        setCall(callType);
    }

    return (
        <div style={{ margin: "20px 60px", padding: "20px 60px", borderRadius:"12px", fontFamily: "Arial, sans-serif", boxShadow: "2px 4px 2px 4px rgba(0, 0, 0, 0.1)" }}>
            <h1 style={{ color: "#2c3e50" }}>Converter Module</h1>
            <p style={{ marginBottom: "20px" }}>Select a file and choose a format to convert.</p>
            <div style={{ marginBottom: "20px", width: "100%" }}>
                <select
                    style={{ padding: "10px", fontSize: "16px", width: "100%", marginBottom: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                >
                    <option value="" disabled>Select a file</option>
                    {fileSelectorFiles.map((file) => (
                        <option key={file.file_id} value={file.file_name}>
                            {file.file_name}
                        </option>
                    ))}
                </select>

                <Popover content={
                    <Menu>
                        <MenuItem text="E57 TO PLY" onClick={() => handleMenuItemClick(".ply", 1)} />
                        <MenuItem text="E57 TO STL" onClick={() => handleMenuItemClick(".stl", 2)} />
                        <MenuItem text="E57 TO GLTF" onClick={() => handleMenuItemClick(".glb/gltf", 3)} />
                        <MenuItem text="PLY to GLTF" onClick={() => handleMenuItemClick("PLY to GLTF", 4)} />
                        <MenuItem text="STL to GLTF" onClick={() => handleMenuItemClick("STL to GLTF", 5)} />
                        <MenuItem text="OBJ to USD" onClick={() => handleMenuItemClick("OBJ to USD", 6)} />
                    </Menu>
                } placement="bottom">
                    <Button alignText="left" icon="applications" rightIcon="caret-down" text={convertText} style={{ width: "100%", marginBottom: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }} />
                </Popover>
                <Button
                    className=".bp3-intent-primary"
                    alignText="center"
                    text={loading ? <Spinner size={16} /> : "Convert"}
                    onClick={handleConvert}
                    style={{ width: "100%" }}
                    disabled={loading}
                />
                {message && <p style={{ marginTop: "20px", color: message.includes("Successfully") ? "green" : "red" }}>{message}</p>}
            </div>
        </div>
    );
}
