import { saveToFile } from "../utils";
import {
  PipeRack,
  CircularSF,
  RectangularSF,
  RectangularBP,
  CircularBP,
  Project,
  LadderParams,
  Loadings,
  DL_FPost,
  DirectLoad,
  EquipmentLoad,
} from "../../../store/main/types";
import {
  RolledSection,
  Section,
  Material,
  PipeProfile,
  CombinedSection,
} from "../../../store/data/types";
import { Dispatch } from "redux";
import {
  initLadderParams,
  initLoadings,
  dampingRatios,
} from "../../../store/main/constants";
import { Vector3 } from "three";
import { getProfile, getMaterial, getPipeProfile } from "./xchUtils";
import { createXCHProject } from "../../../store/main/actions";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { focusTarget } from "../../../store/scene/actions";

export function exportPRs(
  controls: OrbitControls | undefined,
  project: Project,
  fabricatedSections: Section[],
  rolledSections: RolledSection[],
  combinedSections: CombinedSection[]
) {
  const models = project.models.filter(
    (model) => model.type === "Pipe Rack"
  ) as PipeRack[];
  const xchArr = {
    camera: controls
      ? {
          target: {
            x: controls.target.x,
            y: controls.target.y,
            z: controls.target.z,
          },
          position: {
            x: controls.object.position.x,
            y: controls.object.position.y,
            z: controls.object.position.z,
          },
        }
      : undefined,
    type: "Pipe Rack",
    models: models.map((model) => ({
      name: model.name,
      direction: model.direction,
      baseElevation: model.baseElevation ?? 0,
      position: {
        x: model.startPos.x,
        y: model.startPos.y,
        z: model.startPos.z,
      },
      library: model.CSLibrary,
      beamProfile: model.portalBeamProfile.designation,
      colProfile: model.portalColProfile.designation,
      tieProfile: model.portalTieProfile.designation,
      material: model.material?.material_name,
      portals: model.portals.map((portal) => ({
        name: portal.name,
        chainage: portal.chainage,
        tiers: portal.tiers.reduce(
          (acc, tier, i) => ({ ...acc, [i + 1]: tier }),
          {}
        ),
        length: portal.length,
        width: portal.width,
        supportType: portal.supportType,
      })),
      columns: model.columns.map((column) => ({
        parent: column.parent,
        name: column.name,
        tier: column.tier,
        start: column.startPos,
        end: column.endPos,
        side: column.side,
        orientation: column.orientation ?? 0,
        library: column.CSLibrary,
        profile: column.profile.designation,
        isNext: column.isNext,
        next: column.next,
        onGround: column.onGround,
        type: column.type,
        additional: column.additional,
        lowerBeam: column.lowerBeam,
        upperBeam: column.upperBeam,
        releases: column.releases,
        memberId: column.memberId,
      })),
      beams: model.beams.map((beam) => ({
        type: beam.type,
        parent: beam.parent,
        name: beam.name,
        tier: beam.tier,
        start: beam.startPos,
        end: beam.endPos,
        side: beam.side,
        elevation: beam.elevation ?? 0,
        orientation: beam.orientation ?? 0,
        direction: beam.direction,
        library: beam.CSLibrary,
        profile: beam.profile.designation,
        next: beam.next,
        splitters: beam.splitters,
        additional: beam.additional,
        releases: beam.releases,
        memberId: beam.memberId,
      })),
      cantilevers: model.cantilevers.map((cnt) => ({
        type: cnt.type,
        parent: cnt.parent,
        name: cnt.name,
        tier: cnt.tier,
        start: cnt.startPos,
        end: cnt.endPos,
        length: cnt.length,
        orientation: cnt.orientation ?? 0,
        library: cnt.CSLibrary,
        profile: cnt.profile.designation,
        next: cnt.next,
        splitters: cnt.splitters,
        side: cnt.side,
        sideType: cnt.sideType,
        position: cnt.position,
        positionType: cnt.positionType,
        releases: cnt.releases,
        memberId: cnt.memberId,
      })),
      vbracings: model.vBracings.map((vb) => ({
        type: vb.type,
        parent: vb.parent,
        name: vb.name,
        tier: vb.tier,
        start: vb.startPos,
        end: vb.endPos,
        orientation: vb.orientation,
        library: vb.CSLibrary,
        profile: vb.profile.designation,
        side: vb.side,
        sideType: vb.sideType,
        bracingType: vb.bracingType,
        releases: vb.releases,
        memberId: vb.memberId,
      })),
      hbracings: model.hBracings.map((hb) => ({
        type: hb.type,
        parent: hb.parent,
        name: hb.name,
        tier: hb.tier,
        start: hb.start,
        end: hb.end,
        elevation: hb.elevation,
        startOffset: hb.startOffset,
        endOffset: hb.endOffset,
        orientation: hb.orientation,
        library: hb.CSLibrary,
        profile: hb.profile.designation,
        releases: hb.releases,
        memberId: hb.memberId,
      })),
      pipes: model.pipes.map((pipe) => ({
        parent: pipe.parent,
        name: pipe.name,
        start: pipe.start,
        end: pipe.end,
        direction: pipe.direction,
        fromPortal: pipe.fromPortal,
        toPortal: pipe.toPortal,
        startLeftDist: pipe.startLeftDist,
        startBayDist: pipe.startBayDist,
        endLeftDist: pipe.endLeftDist,
        endBayDist: pipe.endBayDist,
        diameter: pipe.diameter,
        thickness: pipe.thickness,
        library: pipe.lib,
        nps: pipe.profile?.nominal_pipe_size_inch,
        schedule: pipe.profile?.schedule,
        material: pipe.material?.material_name,
        succeeding: pipe.succeeding,
        supTypes: pipe.supTypes,
      })),
      accessories: model.accessories.map((ag) => ({
        parent: ag.parent,
        name: ag.name,
        tier: ag.tier,
        side: ag.side,
        type: ag.type,
        orientation: ag.orientation,
        spacing: ag.spacing,
        count: ag.count,
        distanceFromStart: ag.distanceFromStart,
        elements: ag.elements.map((el: any) => ({
          index: el.index,
          name: el.name,
          totalH: el.totalH,
          h1: el.h1,
          h2: el.h2,
          h3: el.h3,
          h4: el.h4,
          projection: el.projection,
          leftProjection: el.leftProjection,
          rightProjection: el.rirightProjection,
          colOrientation: el.colOrientation,
          colCSLibrary: el.colCSLibrary,
          colProfile: el.colProfile.designation,
          beamOrientation: el.beamOrientation,
          beamCSLibrary: el.beamCSLibrary,
          beamProfile: el.beamProfile.designation,
          colItems: el.colItems.map((item: any) => ({
            start: item.startPos,
            end: item.endPos,
          })),
          beamItems: el.beamItems.map((item: any) => ({
            position: item.position,
            start: item.startPos,
            end: item.endPos,
          })),
        })),
      })),
      platforms: model.platforms
        .filter((pl) => pl.valid)
        .map((pl) => ({
          name: pl.name,
          fromPortal: pl.fromPortal,
          toPortal: pl.toPortal,
          tier: pl.tier,
          side: pl.side,
          width: pl.width,
          thickness: pl.thickness,
        })),
      ladderParams: {
        spacing: project!.ladderParams.spacing,
        rungDia: project!.ladderParams.rungDia,
        rungSpacing: project!.ladderParams.rungSpacing,
        CHBw: project!.ladderParams.CHBw,
        CHBt: project!.ladderParams.CHBt,
        CHBs: project!.ladderParams.CHBs,
        CHBd: project!.ladderParams.CHBd,
        CVBw: project!.ladderParams.CVBw,
        CVBt: project!.ladderParams.CVBt,
        CVBnos: project!.ladderParams.CVBnos,
        CHR: project!.ladderParams.CHR,
        library: project!.ladderParams.lib,
        profile: project!.ladderParams.profile?.designation,
      },
      ladders: model.ladders.map((ld) => ({
        name: ld.name,
        platform: ld.platform,
        onFace: ld.onFace,
        distanceFromLeft: ld.distanceFromLeft,
        fromElevation: ld.fromEL,
      })),
      plates: model.plates.map((plate) => ({
        name: plate.name,
        parent: plate.parent,
        type: plate.type,
        column: plate.column,
        designMethod: plate.designMethod,
        bPlateThickness: plate.bPlateThickness,
        grade: plate.grade,
        boltDiameter: plate.boltDiameter,
        tension: plate.tension,
        shear: plate.shear,
        sPlateThickness: plate.sPlateThickness,
        sPlateHeight: plate.sPlateHeight,

        bPlateDiameter: (plate as CircularBP).bPlateDiameter,
        boltBCD: (plate as CircularBP).boltBCD,
        boltNos: (plate as CircularBP).boltNos,
        sPlateWidth: (plate as CircularBP).sPlateWidth,
        sPlateNos: (plate as CircularBP).sPlateNos,

        bPlateLength: (plate as RectangularBP).bPlateLength,
        bPlateWidth: (plate as RectangularBP).bPlateWidth,
        alongLength: (plate as RectangularBP).alongLength,
        alongWidth: (plate as RectangularBP).alongWidth,
        firstRowLength: (plate as RectangularBP).firstRow_L,
        rowToRowLength: (plate as RectangularBP).RtoR_L,
        firstRowWidth: (plate as RectangularBP).firstRow_W,
        rowToRowWidth: (plate as RectangularBP).RtoR_W,
        alongWeb: (plate as RectangularBP).alongWeb,
        alongFlange: (plate as RectangularBP).alongFlange,
      })),
      flanges: model.flanges.map((flange) => ({
        name: flange.name,
        parent: flange.parent,
        type: flange.type,
        column: flange.column,
        designMethod: flange.designMethod,
        bPlateThickness: flange.bPlateThickness,
        grade: flange.grade,
        boltDiameter: flange.boltDiameter,
        tension: flange.tension,
        shear: flange.shear,
        sPlateThickness: flange.sPlateThickness,
        sPlateHeight: flange.sPlateHeight,
        elevation: flange.elevation,
        bBottomPlateThickness: flange.bBottomPlateThickness,

        bPlateDiameter: (flange as CircularSF).bPlateDiameter,
        boltBCD: (flange as CircularSF).boltBCD,
        boltNos: (flange as CircularSF).boltNos,
        sPlateWidth: (flange as CircularSF).sPlateWidth,
        sPlateNos: (flange as CircularSF).sPlateNos,
        bBottomPlateDiameter: (flange as CircularSF).bBottomPlateDiameter,

        bPlateLength: (flange as RectangularSF).bPlateLength,
        bPlateWidth: (flange as RectangularSF).bPlateWidth,
        alongLength: (flange as RectangularSF).alongLength,
        alongWidth: (flange as RectangularSF).alongWidth,
        firstRowLength: (flange as RectangularSF).firstRow_L,
        rowToRowLength: (flange as RectangularSF).RtoR_L,
        firstRowWidth: (flange as RectangularSF).firstRow_W,
        rowToRowWidth: (flange as RectangularSF).RtoR_W,
        alongWeb: (flange as RectangularSF).alongWeb,
        alongFlange: (flange as RectangularSF).alongFlange,
        bBottomPlateLength: (flange as RectangularSF).bBottomPlateLength,
        bBottomPlateWidth: (flange as RectangularSF).bBottomPlateWidth,
      })),
    })),
    fabricatedSections,
    rolledSections,
    combinedSections,
  };
  saveToFile(xchArr, "pipeRacks", "xch");
}

export function importPRs(
  dispatch: Dispatch<any>,
  materials: Material[],
  json: any,
  allProfiles: Section[],
  pipeProfiles: PipeProfile[],
  newFabricatedSections: Section[],
  newRolledSections: RolledSection[],
  newCombinedSections: CombinedSection[]
) {
  let ladderParams: LadderParams = { ...initLadderParams };
  const newModels = json.models.map((model: any) => {
    ladderParams = {
      spacing: model.ladderParams.spacing,
      rungDia: model.ladderParams.rungDia,
      rungSpacing: model.ladderParams.rungSpacing,
      CHBw: model.ladderParams.CHBw,
      CHBt: model.ladderParams.CHBt,
      CHBs: model.ladderParams.CHBs,
      CHBd: model.ladderParams.CHBd,
      CVBw: model.ladderParams.CVBw,
      CVBt: model.ladderParams.CVBt,
      CVBnos: model.ladderParams.CVBnos,
      CHR: model.ladderParams.CHR,
      lib: model.ladderParams.library,
      profile: getProfile(allProfiles, model.ladderParams.profile),
    };
    const newModel: PipeRack = {
      project: "",
      name: model.name,
      type: "Pipe Rack",
      direction: model.direction,
      baseElevation: model.baseElevation ?? 0,
      startPos: new Vector3(
        model.position.x,
        model.position.y,
        model.position.z
      ),
      material: getMaterial(materials, model.material),
      CSLibrary: model.library,
      portalColProfile: getProfile(allProfiles, model.colProfile)!,
      portalBeamProfile: getProfile(allProfiles, model.beamProfile)!,
      portalTieProfile: getProfile(allProfiles, model.tieProfile)!,
      portals: model.portals.map((portal: any, i: number, portals: any[]) => ({
        id: i,
        name: portal.name,
        parent: model.name,
        chainage: portal.chainage,
        tiers: Object.values(portal.tiers),
        length: portal.length,
        width: portal.width,
        position: i ? (i === portals.length - 1 ? "end" : "middle") : "start",
        supportType: portal.supportType,
      })),
      columns: model.columns.map((column: any, i: number) => ({
        id: i,
        name: column.name,
        parent: column.parent,
        type: column.type,
        tier: column.tier,
        side: column.side,
        startPos: new Vector3(column.start.x, column.start.y, column.start.z),
        endPos: new Vector3(column.end.x, column.end.y, column.end.z),
        CSLibrary: column.library,
        profile: getProfile(allProfiles, column.profile),
        orientation: column.orientation,
        additional: column.additional,
        lowerBeam: column.lowerBeam,
        upperBeam: column.upperBeam,
        isNext: column.isNext,
        next: column.next,
        onGround: column.onGround,
        releases: column.releases,
        memberId: column.memberId,
      })),
      beams: model.beams.map((beam: any, i: number) => ({
        id: i,
        name: beam.name,
        parent: beam.parent,
        type: beam.type,
        tier: beam.tier,
        side: beam.side,
        direction: beam.direction,
        startPos: new Vector3(beam.start.x, beam.start.y, beam.start.z),
        endPos: new Vector3(beam.end.x, beam.end.y, beam.end.z),
        CSLibrary: beam.library,
        profile: getProfile(allProfiles, beam.profile),
        orientation: beam.orientation,
        next: beam.next,
        splitters: beam.splitters,
        additional: beam.additional,
        releases: beam.releases,
        memberId: beam.memberId,
      })),
      cantilevers: model.cantilevers.map((cnt: any, i: number) => ({
        id: i,
        type: cnt.type,
        name: cnt.name,
        parent: cnt.parent,
        tier: cnt.tier,
        side: cnt.side,
        sideType: cnt.sideType,
        position: cnt.position,
        positionType: cnt.positionType,
        startPos: new Vector3(cnt.start.x, cnt.start.y, cnt.start.z),
        endPos: new Vector3(cnt.end.x, cnt.end.y, cnt.end.z),
        length: cnt.length,
        CSLibrary: cnt.library,
        profile: getProfile(allProfiles, cnt.profile),
        orientation: cnt.orientation,
        next: cnt.next,
        splitters: cnt.splitters,
        releases: cnt.releases,
        memberId: cnt.memberId,
      })),
      vBracings: model.vbracings.map((vb: any, i: number) => ({
        id: i,
        name: vb.name,
        parent: vb.parent,
        type: vb.type,
        tier: vb.tier,
        side: vb.side,
        sideType: vb.sideType,
        bracingType: vb.bracingType,
        startPos: new Vector3(vb.start.x, vb.start.y, vb.start.z),
        endPos: new Vector3(vb.end.x, vb.end.y, vb.end.z),
        CSLibrary: vb.library,
        profile: getProfile(allProfiles, vb.profile),
        orientation: vb.orientation,
        isUp: vb.start.y < vb.end.y,
        releases: vb.releases,
        memberId: vb.memberId,
      })),
      hBracings: model.hbracings.map((hb: any, i: number) => ({
        id: i,
        name: hb.name,
        parent: hb.parent,
        type: hb.type,
        tier: hb.tier,
        start: hb.start,
        end: hb.end,
        startOffset: hb.startOffset,
        endOffset: hb.endOffset,
        CSLibrary: hb.library,
        profile: getProfile(allProfiles, hb.profile),
        releases: hb.releases,
        memberId: hb.memberId,
      })),
      accessories: model.accessories.map((ag: any, i: number) => ({
        id: i,
        name: ag.name,
        parent: ag.parent,
        tier: ag.tier,
        side: ag.side,
        type: ag.type,
        orientation: ag.orientation,
        spacing: ag.spacing,
        count: ag.count,
        distanceFromStart: ag.distanceFromStart,
        elements: ag.elements.map((el: any) => ({
          name: el.name,
          group: ag.name,
          index: el.index,
          side: ag.side,

          totalH: el.totalH,
          h1: el.h1,
          h2: el.h2,
          h3: el.h3,
          h4: el.h4,

          projection: el.projection,
          leftProjection: el.leftProjection,
          rightProjection: el.rightProjection,

          colOrientation: el.colOrientation,
          colCSLibrary: el.colCSLibrary,
          colProfile: getProfile(allProfiles, el.colProfile),

          beamOrientation: el.beamOrientation,
          beamCSLibrary: el.beamCSLibrary,
          beamProfile: getProfile(allProfiles, el.beamProfile),

          colItems: el.colItems.map((col: any, j: number) => ({
            parent: el.name,
            parentGroup: ag.name,
            type: "AccessoryColumn",
            name: `${el.name}-C${j}`,
            startPos: new Vector3(col.start.x, col.start.y, col.start.z),
            endPos: new Vector3(col.end.x, col.end.y, col.end.z),
            orientation: el.colOrientation,
            profile: getProfile(allProfiles, el.colProfile),
          })),
          beamItems: el.beamItems.map((beam: any, j: number) => ({
            position: beam.position,
            parent: el.name,
            parentGroup: ag.name,
            type: "AccessoryBeam",
            name: `${el.name}-B${j}`,
            startPos: new Vector3(beam.start.x, beam.start.y, beam.start.z),
            endPos: new Vector3(beam.end.x, beam.end.y, beam.end.z),
            orientation: el.beamOrientation,
            profile: getProfile(allProfiles, el.beamProfile),
          })),
        })),
      })),
      pipes: model.pipes.map((pipe: any, i: number) => ({
        ...pipe,
        id: i,
        start: new Vector3(pipe.start.x, pipe.start.y, pipe.start.z),
        end: new Vector3(pipe.end.x, pipe.end.y, pipe.end.z),
        lib: pipe.library,
        profile: getPipeProfile(
          pipeProfiles,
          pipe.lib,
          pipe.nps,
          pipe.schedule
        ),
        material: getMaterial(materials, pipe.material),
      })),
      platforms: model.platforms.map((platform: any, i: number) => ({
        ...platform,
        id: i,
        parent: model.name,
        valid: true,
      })),
      ladders: model.ladders.map((ld: any, i: number) => ({
        id: i,
        name: ld.name,
        parent: ld.platform,
        type: "Ladder",
        platform: ld.platform,
        onFace: ld.onFace,
        distanceFromLeft: ld.distanceFromLeft,
        fromEL: ld.fromElevation,
      })),
      plates: model.plates.map((plate: any, i: number) => ({
        ...plate,
        firstRow_L: plate.firstRowLength,
        RtoR_L: plate.rowToRowLength,
        firstRow_W: plate.firstRowWidth,
        RtoR_W: plate.rowToRowWidth,
        id: i,
      })),
      flanges: model.flanges.map((flange: any, i: number) => ({
        ...flange,
        firstRow_L: flange.firstRowLength,
        RtoR_L: flange.rowToRowLength,
        firstRow_W: flange.firstRowWidth,
        RtoR_W: flange.rowToRowWidth,
        id: i,
      })),
    };
    return newModel;
  });
  const loadings: Loadings = { ...initLoadings };

  dispatch(
    createXCHProject(
      newModels,
      ladderParams,
      newFabricatedSections,
      newRolledSections,
      newCombinedSections,
      loadings
    )
  );
  if (json.camera) {
    dispatch(
      focusTarget(
        new Vector3(
          json.camera.target.x,
          json.camera.target.y,
          json.camera.target.z
        ),
        new Vector3(
          json.camera.position.x,
          json.camera.position.y,
          json.camera.position.z
        )
      )
    );
  }
}
