import React, { useMemo, useState, useEffect } from "react";
import { MenuItem, Navbar, Button, InputGroup, Popover, Position, Tooltip } from "@blueprintjs/core";
import { Menu } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import ShowUserProfile from "../dashboard/profile/userProfile";
import "./NewModeSwitcher.css";
import { API_ROOT, secondServerAPI, secondServerAPILearning } from "../utils/agent";
import Axios from "axios";
import { logOutAction } from "../../store/auth/actions";
import { push } from "connected-react-router";
import { ModelType, TWorkMode } from "../../store/main/types";
import { changeWorkModeAction, setUserRole } from "../../store/main/actions";
import NavbarPrimary from "../dashboard/navbar/navbar";
import ConverterModule from "../dashboard/converter/ConverterModule";
import { createPSSAction } from "../../store/pss/actions";
import { createProcessAction } from "../../store/process/actions";
import { Learnings } from "../education/Learnings";
import { FileUpload } from "../dashboard/file_upload/fileUpload";
import AdminDashboard from "../AdminDashboard/adminDashboard";

export function newModeSwitcher() {
    const [sidebarWidth, setSidebarWidth] = useState(250);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeButton, setActiveButton] = useState("fileUpload");
    const currentProject = useSelector((state: ApplicationState) => state.main.currentProject);
    const [dialog, setDialog] = useState<JSX.Element>();
    const [notifications, setNotifications] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((state: ApplicationState) => state.auth);


    const projects = useSelector(
        (state: ApplicationState) => state.main.projects
    );

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const photoResponse = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/profile_photo`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    },
                    responseType: 'blob',
                });

                if (photoResponse.status === 200) {
                    const url = URL.createObjectURL(photoResponse.data);
                    setProfilePhoto(url);
                }

                const userResponse = await Axios.get(`${secondServerAPILearning}/api/v1/dashboard/userInformation`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    }
                });

                if (userResponse.status === 200) {
                    setUserName(userResponse.data.userName);
                    const userId = userResponse.data.userId;

                    const rolesResponse = await Axios.get(`${API_ROOT}/rest/api/v1/dashboard/get/userroles`, {
                        headers: {
                            "user-id": auth.User_id
                            // "user-id": 1, 
                        }
                    });

                    if (rolesResponse.status === 200) {
                        const users = rolesResponse.data;
                        const loggedInUser = users.find((user: any) => user.userId === userId);
                        dispatch(setUserRole(loggedInUser.role));
                        if (loggedInUser && loggedInUser.role === "Manager") {
                            setIsManager(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    function handleSelectMode(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const mode = event.currentTarget.id as TWorkMode;
        dispatch(changeWorkModeAction(mode));
        if (mode === "LEARNINGS") {
            history.push("/learnings");
            return;
        }
        switch (mode) {
            case "STRUCTURE":
                dispatch(createPSSAction(currentProject));
                break;
            case "PROCESS":
            case "DESIGNER":
            case "PRODESIGNER":
            case "PIPDESIGNER":
            case "STRDESIGNER":
                dispatch(createProcessAction(currentProject));
                break;
        }
        history.push("/editor");
    }

    function handleSidebarResize(e: { clientX: React.SetStateAction<number>; }) {
        setSidebarWidth(e.clientX);
    }

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen);
    }

    function handleButtonClick(button: React.SetStateAction<string>) {
        setActiveButton(button);
    }

    function handleLogOut() {
        window.localStorage.setItem("jwt", "")
        dispatch(logOutAction())
        dispatch(push("/"))
        Axios.get(`${API_ROOT}/rest/api/v1/deleteUser`, {
            headers: { 'user-id': auth.User_id }
        })
            .then(response => {
                console.log("Updated");
            })
    }

    function invokeReportIssue() {
        window.open("https://asets-tech-support.atlassian.net/servicedesk/customer/portal/1", "_blank");
    }

    function handleViewerClick() {
        history.push("/viewer");
    }

    function handleLearnignClick() {
        setActiveButton("fileUpload")
        // history.push("/learnings");
    }

    function handlePCDClick(){
        window.open("https://idspcdloader.asets.io", "_blank");
    }

    function handleConverterClick() {
        setActiveButton("converter");
    }
    function handleAdminDashClick() {
        setActiveButton("AdminDash");
    }

    return (
        <div className="new-mode-switcher-container">
            <NavbarPrimary/>
            <div className="main-content">
                <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: isSidebarOpen ? sidebarWidth : 60 }}>
                    <Button rightIcon={isSidebarOpen ? "arrow-left" : "arrow-right"} onClick={toggleSidebar} className="toggle-sidebar-button" style={{ paddingTop: "20px" }} />
                    {isSidebarOpen ? (
                        <div className="sidebar-content">
                            <div className="sidebar-menu-item">
                                <Popover
                                    content={
                                        <Menu className="file-menu">
                                            {/* <MenuItem className="file-menu-item" id={"DESIGNER"} icon="draw" text="integrator" onClick={handleSelectMode} /> */}
                                            <MenuItem className="file-menu-item" icon="comparison" text="Viewer" onClick={handleViewerClick} />
                                            <MenuItem className="file-menu-item" icon="grid-view" text="SCANS Viewer" onClick={handlePCDClick} />
                                        </Menu>
                                    }
                                    position={Position.LEFT}
                                >
                                    <Button className={`editor-launch-button ${activeButton === 'launch' ? 'active' : ''}`} icon="rocket-slant" text="Choose Option" onClick={() => handleButtonClick('launch')} />
                                </Popover>
                            </div>
                            <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'home' ? 'active' : ''}`} icon="learning" text="File Manager" onClick={handleLearnignClick} />  
                            </div>
                            <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'home' ? 'active' : ''}`} icon="translate" text="Converter" onClick={handleConverterClick} />
                            </div>
                            {auth.User_id === 1 ? (
                                <div className="sidebar-menu-item">
                                    <Button className={`editor-button ${activeButton === 'home' ? 'active' : ''}`} icon="translate" text="Admin Dash" onClick={handleAdminDashClick} />
                                </div>
                            ): null}
                            {/* <div className="sidebar-menu-item">
                                    <Button className={`editor-button ${activeButton === 'home' ? 'active' : ''}`} icon="translate" text="Admin Dash" onClick={handleAdminDashClick} />
                            </div> */}
                        </div>
                    ) : (   
                        <div className="sidebar-icons">
                            <Tooltip content = "Home">
                                <div className="menu-item"><Button icon="learning" minimal onClick={handleLearnignClick} /></div>
                            </Tooltip>
                        </div>
                    )}
                    <div className="sidebar-resizer" onMouseDown={() => {
                        document.addEventListener('mousemove', handleSidebarResize);
                        document.addEventListener('mouseup', () => {
                            document.removeEventListener('mousemove', handleSidebarResize);
                        }, { once: true });
                    }} />
                </div>
                <div className="content" style={{ marginLeft: isSidebarOpen ? sidebarWidth : 60 }}>
                    {activeButton === 'fileUpload' && <FileUpload/>}
                    {activeButton === 'converter' && <ConverterModule/>}
                    {activeButton === 'profile' && <ShowUserProfile />}
                    {activeButton === 'AdminDash' && <AdminDashboard />}
                </div>
            </div>
            {dialog}
        </div>
    );
}
