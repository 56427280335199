import React from 'react';
import './chatbot.css';
import api from './api.js';
import logo from '../../assets/AsetsLuxLogo.JPG';
import logo_1 from '../../assets/A-Logo-White-Transparent.png'
import botFace from '../../assets/bot_face.webp'
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

class Chatbot extends React.Component {
  constructor(props) {
    super(props);
    const theme = localStorage.getItem('theme') || 'light';
    this.state = {
        messages: [],
        input: '',
        isChatbotOpen: false,
        theme: theme,
        showBotFace: true
    };
  }

  handleChange = (event) => {
    this.setState({ input: event.target.value });
  }
  addWelcomeMessage = () => {
    this.setState(state => ({
      messages: [...state.messages, { text: 'Welcome, how can I help you?', sender: 'bot' }]
    }));
  }
  addShortcutMessage = () => {
    this.setState(state => ({
      messages: [...state.messages, { text: 'You can use the following shortcuts in IDS:', sender: 'bot', highlight: true },
      { text: 'Select an equipment/pipe element/structure element : Double click ', sender: 'bot', highlight: false },
      { text: 'Select a connection nozzle of the equipment: Ctrl + click', sender: 'bot', highlight: false },
      { text: 'Select the nozzle starting point on the equipment: Ctrl + click', sender: 'bot', highlight: false },
      { text: 'Add pipe elements on the suggested yellow bubble: Ctrl + click', sender: 'bot',highlight: false },
      { text: 'Add connections on the suggested yellow bubble: Ctrl + click', sender: 'bot', highlight: false },
      { text: 'Move the grid: Ctrl + drag', sender: 'bot', highlight: false },
      { text: 'Move the equipment: Shift+drag ', sender: 'bot', highlight: false },
      { text: 'Search for the lines in the piping module: Ctrl+Shift+S ', sender: 'bot', highlight: false },
      { text: 'Search for the structure elements in the structure module: Ctrl+Shift+S ', sender: 'bot', highlight: false },
      { text: 'Change focal pivot point (on a particular equipment): Ctrl+shift+click ', sender: 'bot', highlight: false },
      {text:  'Create vertical bracing: Select vertical bracing icon from the Structure Tools window, enter details, and Ctrl+click on two columns first and then the two beams between which the bracing should appear.', sender: 'bot', highlight: false},
      {text:  'Create horizontal bracing: Select horizontal bracing icon from the Structure Tools window, Ctrl+Click on two beams between which the bracing should appear.', sender: 'bot', highlight: false},
      {text:  'Create knee bracing: Select knee bracing icon from the Structure Tools window, Ctrl+click on beam first and then the column between which knee bracing should appear.', sender: 'bot', highlight: false},
      {text:  'Create beam: Select beam icon from the Structure Tools window, Ctrl+click on two column between which the beam should appear.', sender: 'bot', highlight: false},
      {text:  'Create platform: Ctrl+click two beams between which the platform should appear', sender: 'bot', highlight: false},
      {text:  'Create connection - base plate: From 3D Modeling → Connections → Bolted → ODSM → Beam Connections window, select BP icon and ctrl+click on the yellow bubbles that show up on the open frames.', sender: 'bot', highlight: false},
      {text:  'Create connection for entire open frame: From 3D Modeling → Open Frame → Connections table, after ctrl+click on atleast one yellow bubbles of an open frame, select the checkbox and click “Add for All” button', sender: 'bot', highlight: false},
    ]
    }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const userInput = this.state.input;

    this.setState((state) => ({
      messages: [...state.messages, { text: userInput, sender: 'user' }],
      input: ''
    }));

    try {
      const response = await api.post('/ask', { question: userInput });
      const botResponse = response.data.response.response;

      this.setState((state) => ({
        messages: [...state.messages, { text: botResponse, sender: 'bot' }]
      }));
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }
  // toggleChatbotWindow = () => {
  //   this.setState((prevState) => ({
  //     isChatbotOpen: !prevState.isChatbotOpen,
  //     messages: !prevState.isChatbotOpen ? prevState.messages : [],
  //   }));
  //   if (!this.state.isChatbotOpen) { 
  //     this.addWelcomeMessage();
  //   }
  // }

  toggleChatbotWindow = () => {
    this.setState((prevState) => ({
      isChatbotOpen: !prevState.isChatbotOpen,
      messages: !prevState.isChatbotOpen ? prevState.messages : [],
    }));
  
    if (!this.state.isChatbotOpen) { 
      this.addWelcomeMessage();
    }
  }

  toggleTheme = () => {
    const newTheme = this.state.theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    this.setState({ theme: newTheme });
  }
  

  render() {
    const { theme, isChatbotOpen, showBotFace } = this.state;
    return (
        <div className={`chatbot-container ${theme}`}>
            {!isChatbotOpen && (
            <div className="chatbot-floating-button" onClick={this.toggleChatbotWindow}>
                <div className="logo-container">
                    <img src={logo} alt='Logo' className="logo"/>
                </div>
            </div>
            )}
            {isChatbotOpen && (
            <div className="chatbot-window">
                <div className="chatbot-header">
                    <div className="header-content">
                        <img src={logo_1} alt='Logo' style={{ width: '50px', marginRight: '10px' }}/>
                        IDS<span>™</span> Bot
                        </div>
                        <div className="button-container">
                            {/*<button onClick={this.toggleTheme} className="button">
                                {this.state.theme === 'light' ? 'Dark Mode' : 'Light Mode'}
                            </button>*/}
                            <div className="close-button-container">
                              <button className="round-close-button" onClick={this.toggleChatbotWindow}>
                                - 
                              </button>
                            </div>
                            {/* <Button 
                                icon={<Icon icon={IconNames.CROSS} />}
                                onClick={this.toggleChatbotWindow}
                                className="button"
                                style={{
                                  backgroundColor: '#F1C40F', 
                                  color: 'black', 
                                  border: 'none', 
                                  fontWeight: 'bold',
                                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
                                }}
                              >
                                Close
                              </Button> */}
                            <Button 
                              icon={<Icon icon={IconNames.FLASH} />} 
                              onClick={this.addShortcutMessage} 
                              className="button"
                              style={{
                                backgroundColor: '#F1C40F', 
                                color: 'black', 
                                border: 'none', 
                                fontWeight: 'bold',
                                borderRadius: '12px',
                                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
                              }}
                            >
                              Shortcuts
                            </Button>
                        </div>
                    </div>
                    <div className="chatbot-messages">
                      {this.state.messages.map((message, index) => (
                        <div key={index} className={`chatbot-message ${message.sender}-message`}>
                          {message.sender === 'bot' && showBotFace && <img src={botFace} alt="Bot" className="bot-face-image" />}
                          <p>{message.text}</p>
                        </div>
                      ))}
                    </div>
                    <div className="chatbot-input-area">
                        <form onSubmit={this.handleSubmit}>
                            <input
                            className="chatbot-input"
                            type="text"
                            placeholder="Ask your Query..."
                            value={this.state.input}
                            onChange={this.handleChange}
                            />
                            <Button 
                              icon={<Icon icon={IconNames.SEND_TO} />} 
                              className="button"
                              type='Submit'
                              style={{
                                backgroundColor: '#F1C40F', 
                                color: 'black', 
                                border: 'none', 
                                fontWeight: 'bold',
                                borderRadius: '12px',
                                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
                              }}
                            >
                              Send
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </div>        
    );
  }
}

export default Chatbot;