import { MenuItem, Navbar, Button, InputGroup, Popover, Position, Tooltip, Menu } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { saveToPDF } from "../dashboard-utils";
import Logo from "../../../assets/A-Logo-White-Transparent.png";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { push } from "connected-react-router";
import { logOutAction } from "../../../store/auth/actions";
import { API_ROOT, secondServerAPI, secondServerAPILearning } from "../../utils/agent";
import { ApplicationState } from "../../../store";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { setUserRole } from "../../../store/main/actions";
import { useHistory } from "react-router-dom";


export default function NavbarPrimary() {
    const [userName, setUserName] = useState<string>("");
    const [showHelpDropdown, setShowHelpDropdown] = useState<boolean>(false);
    const [activeButton, setActiveButton] = useState("");
    const [profilePhoto, setProfilePhoto] = useState<string>("https://www.w3schools.com/howto/img_avatar.png");
    const auth = useSelector((state: ApplicationState) => state.auth);
    const [input, setInput] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const photoResponse = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/profile_photo`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    },
                    responseType: 'blob',
                });

                if (photoResponse.status === 200) {
                    const url = URL.createObjectURL(photoResponse.data);
                    setProfilePhoto(url);
                }

                const userResponse = await Axios.get(`${secondServerAPILearning}/api/v1/dashboard/userInformation`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    }
                });

                if (userResponse.status === 200) {
                    setUserName(userResponse.data.userName);
                    const userId = userResponse.data.userId;

                    const rolesResponse = await Axios.get(`${API_ROOT}/rest/api/v1/dashboard/get/userroles`, {
                        headers: {
                            "user-id": auth.User_id
                            // "user-id": 1, 
                        }
                    });

                    if (rolesResponse.status === 200) {
                        const users = rolesResponse.data;
                        const loggedInUser = users.find((user: any) => user.userId === userId);
                        dispatch(setUserRole(loggedInUser.role));
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    function handleButtonClick(button: React.SetStateAction<string>) {
        setActiveButton(button);
    }

    function handleLogOut() {
        window.localStorage.setItem("jwt", "")
        dispatch(logOutAction())
        dispatch(push("/"))
        Axios.get(`${API_ROOT}/rest/api/v1/deleteUser`, {
            headers: { 'user-id': auth.User_id }
        })
            .then(response => {
                console.log("Updated");
            })
    }

    function handleInputChange() {

    }

    function invokeReportIssue() {
        window.open("https://asets-tech-support.atlassian.net/servicedesk/customer/portal/1", "_blank");
    }
    
    function handleImageClick() {
        history.push('/modes')
    }
    
    return (
        <>
            <Navbar className="primary-newmodeswitcher-navbar">
                <div className="secondary-newmodeswitcher-navbar">
                    <img src={Logo} alt="Logo" className="navbar-logo" onClick={handleImageClick} />
                    <h1 className="navbar-title">ASETS</h1>
                    <div className="tertiary-newmodeswitcher-navbar">
                        <InputGroup
                            large
                            type="text"
                            leftIcon="search"
                            className="search-input-group"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Search..."
                            style={{
                                borderRadius: "12px",
                            }}
                        />
                        <span className="welcome-text">Welcome, <span style={{ color: "#F1C40F", fontWeight: "bold" }}>{userName}</span></span>
                        <Popover
                            content={
                                <Menu className="file-menu">
                                    {/* <MenuItem className="file-menu-item" icon="person" text="profile" onClick={() => { handleButtonClick("profile") }} /> */}
                                    <MenuItem
                                        className="file-menu-item"
                                        icon="help"
                                        text="help"
                                        onMouseEnter={() => setShowHelpDropdown(true)}
                                    >
                                        {showHelpDropdown && (
                                            <MenuItem
                                                className="file-menu-item"
                                                text="report an issue"
                                                onClick={invokeReportIssue}
                                            />
                                        )}
                                    </MenuItem>
                                    <MenuItem className="file-menu-item" icon="log-out" text="logout" onClick={handleLogOut} />
                                </Menu>
                            }
                            position={Position.BOTTOM}
                            className="avatar-container"
                        >
                            <div className="avatar-and-icon">
                                <img src={profilePhoto} alt="Avatar" className="nav-avatar" />
                                <FontAwesomeIcon icon={faAngleDoubleDown} className="down-arrow-icon" style={{ color: "#1F8EB7", fontSize: "1.5rem" }} />
                            </div>
                        </Popover>
                    </div>
                </div>
            </Navbar>
        </>
    )
}

