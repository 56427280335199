import React, { useEffect, useState } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import Axios from "axios";
import { secondServerAPI } from "../../../pages/utils/agent";
import { Project } from "../../../store/main/types";
import { Button } from "@blueprintjs/core";
import { openGenericProject } from "../../3d-models/utils";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { openDesigner } from "./projectUtils";

type Props = {
    onClose: () => any;
};

type Project = {
    projectId: number;
    projectName: string;
    companyName: string;
    projectPhase: string;
};

export function ProjectDownloadingDLG(props: Props) {
    const [projectinfo, setProjectinfo] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState("");

    const dispatch = useDispatch();

    const auth = useSelector((state: ApplicationState) => state.auth);

    const projects = useSelector(
        (state: ApplicationState) => state.main.projects
    );

    const mode = useSelector((state: ApplicationState) => state.main.workMode);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/userandprojects`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    },
                });
                if (response.status === 200) {
                    const projectsData = response.data.projectResponses.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));
                    setProjectinfo(projectsData);
                    if (projectsData.length > 0) {
                        setSelectedProject(projectsData[0].projectName);
                    }
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, [auth.User_id]);

    const onClose = () => {
        props.onClose();
    };

    async function handleProjectDownload() {
        const selectedProj = projectinfo.find(
            proj => proj.projectName === selectedProject
        );
        const projectId = selectedProj ? selectedProj.projectId : null;
        if (projectId) {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/layouts/getProjectFile`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "project-id": projectId,
                    },
                });
                if (response.status === 200) {
                    console.log(response.data);
                    openDesigner(dispatch, projects, response.data, selectedProject);
                    onClose();
                }
            } catch (error) {
                console.error("Error downloading project file:", error);
            }
        } else {
            console.error("No project selected or project ID not found");
        }
    }

    return (
        <>
            <CustomDlg
                title="Get Project"
                body={
                    <div className="d-flex vertical" style={{ flexDirection: "column", padding: "5px" }}>
                        <div className="d-flex" style={{ margin: "5px" }}>
                            <div style={{ margin: 5, color: "white", fontSize: 14 }}>Project Available:</div>
                            <select
                                className="bp3-select w-100"
                                value={selectedProject}
                                onChange={(e) => setSelectedProject(e.target.value)}
                            >
                                {projectinfo.map((proj) => (
                                    <option key={proj.projectId} value={proj.projectName}>
                                        {proj.projectName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <Button text="Get Project File" className="bp3-intent-success bp3-large bp3-icon-cloud-download table-area-button" onClick={handleProjectDownload} />
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
