import { Button, Checkbox, Icon, InputGroup, Tooltip, Dialog, FormGroup, Spinner, ProgressBar } from "@blueprintjs/core";
import React, { useState, ChangeEvent, useEffect } from "react";
import Axios from "axios";
import "./fileUpload.css";
import { secondServerAPI } from "../../utils/agent";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";

interface File {
    id: string;
    name: string;
    size: number;
    type: string;
    lastModified: Date;
    preview: string;
    downloadLink: string;
}

export function FileUpload() {
    const [files, setFiles] = useState<File[]>([]);
    const [sharedFiles, setSharedFiles] = useState<File[]>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [view, setView] = useState<"grid" | "list">("list");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [activeTab, setActiveTab] = useState<"myFiles" | "sharedFiles">("myFiles");
    const [fileTypeFilter, setFileTypeFilter] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [downloadProgress, setDownloadProgress] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const supported3DFormats = ["e57", "obj", "stl", "ply", "fbx", "3ds", "gltf", "glb"];
    const auth = useSelector((state: ApplicationState) => state.auth);

    useEffect(() => {
        Axios.get(`https://lbidsufc8080.asets.io/node/trial`)
    })

    useEffect(() => {
        if (activeTab === "myFiles") {
            fetchFiles();
        } else if (activeTab === "sharedFiles") {
            fetchSharedFiles();
        }
    }, [activeTab]);

    function fetchFiles() {
        Axios.get(`https://lbidsufc8000.asets.io/list-files-info`, {
            headers: {
                'user-id' : auth.User_id,
            }
        }).then(response => {
            console.log("call made successfully", response);
            const fetchedFiles = response.data.files.map((file: any) => ({
                id: file.file_id,
                name: file.file_name,
                size: file.file_size_in_mb,
                type: file.file_type,
                lastModified: new Date(),
                preview: '',
                downloadLink: '',
            }));
            setFiles(fetchedFiles);
        }).catch(error => {
            console.log("call failed", error);
        });
    }

    function handleAddtoMyFile(fileId: string) {
        Axios.post(`${secondServerAPI}/rest/api/v1/ufc/addFilesToUploadedFromShared`, {
            userId: auth.User_id,
            fileId: fileId
        }).then(response => {
            if (response.status === 200) {
                alert("File added to your files successfully");
            } else {
                alert("Failed to add file to your files");
            }
        }).catch(error => {
            console.error("Error adding file to your files:", error);
            alert("Failed to add file to your files");
        });
    }

    function fetchSharedFiles() {
        Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getSharedFileDetailsForUser`, {
            headers: {
                'user-id' : auth.User_id,
            }
        }).then(response => {
            if (response.status === 200) {
                const fetchedFiles = response.data.map((file: any) => ({
                    id: file.fileId,
                    name: file.fileName,
                    size: file.fileSizeInMB,
                    type: file.fileType,
                    lastModified: new Date(file.createdOn),
                    preview: '',
                    downloadLink: file.fileAddress,

                }));
                setSharedFiles(fetchedFiles);
            } else if (response.status === 404) {
                setSharedFiles([]);
            }
        }).catch(error => {
            console.error("Error fetching shared files:", error);
            setSharedFiles([]);
        });
    }

    function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
        setIsLoading(true);
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles).map(file => {
                const fileSizeInMB = file.size / (1024 * 1024);
                if (fileSizeInMB > 1000) {
                    alert("File size must be less than 1GB.");
                    return null;
                }
                let fileType = file.type;
                if (!fileType) {
                    const extension = file.name.split('.').pop()?.toLowerCase();
                    const mimeTypes: { [key: string]: string } = {
                        'stl': 'application/vnd.ms-pkistl',
                        'obj': 'application/x-tgif',
                        'fbx': 'application/octet-stream',
                        'glb': 'model/gltf-binary',
                        'gltf': 'model/gltf+json',
                        'dae': 'model/vnd.collada+xml',
                        'e57': 'model/vnd.e57+xml',
                        'ply': 'model/vnd.ply+xml',
                    };
                    fileType = mimeTypes[extension || ''] || 'application/octet-stream';
                }

                return {
                    id: String(new Date().getTime() + Math.random()),  
                    name: file.name,
                    size: fileSizeInMB,
                    type: fileType,
                    lastModified: new Date(file.lastModified),
                    preview: URL.createObjectURL(file),
                    downloadLink: URL.createObjectURL(file),
                };
            }).filter(Boolean) as File[];

            setFiles((prevFiles) => [...prevFiles, ...newFiles]);

            Array.from(selectedFiles).forEach(file => {
                const formData = new FormData();
                formData.append('file', file);

                Axios.post(`https://lbidsufc8080.asets.io/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'user-id': auth.User_id,
                        'file-name': file.name,
                        'file-type': file.type,
                        'file-size-in-mb': (file.size / (1024 * 1024)).toString(),
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(`File Upload Progress: ${percentCompleted}%`);
                        setUploadProgress(percentCompleted);
                    }
                })
                .then(response => {
                    alert('File Upload Success');
                    console.log('File uploaded successfully:', response.data);
                    setUploadProgress(0);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                    setUploadProgress(0);
                    setIsLoading(false);
                });
            });
        } else {
            alert("Something went wrong! Please try again...");
            setIsLoading(false);
        }
    }

    function triggerFileInput() {
        document.getElementById("fileInput")?.click();
    }

    function handleCheckboxChange(fileId: string, isChecked: boolean) {
        setSelectedFileIds(prevSelectedFileIds => {
            if (isChecked) {
                return [...prevSelectedFileIds, fileId];
            } else {
                return prevSelectedFileIds.filter(id => id !== fileId);
            }
        });
    }

    function handleSelectAll(isChecked: boolean) {
        if (isChecked) {
            const fileIds = activeTab === "myFiles" ? filteredFiles.map(file => file.id) : filteredSharedFiles.map(file => file.id);
            setSelectedFileIds(fileIds);
        } else {
            setSelectedFileIds([]);
        }
    }

    function handleDeleteFile() {
        setIsLoading(true);
        const deleteFilePromises = selectedFileIds.map(fileId => {
            return Axios.delete(`${secondServerAPI}/rest/api/v1/ufc/deleteUploadedFile`, {
                headers: {
                    'file-id': fileId,
                    'user-id': auth.User_id,
                }
            });
        });

        Promise.all(deleteFilePromises)
            .then(responses => {
                const allSuccessful = responses.every(response => response.status === 200);
                if (allSuccessful) {
                    console.log("All files deleted successfully");
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("File deleted successfully");
                    fetchFiles();
                } else {
                    const failedDeletes = responses.filter(response => response.status !== 200);
                    console.log(`${failedDeletes.length} files failed to delete.`);
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("Failed to delete some files");
                }
            })
            .catch(error => {
                console.error("Error deleting files:", error);
                setIsLoading(false);
                setIsDialogOpen(false);
                alert("Failed to delete files");
            });
    }

    function handleShareFile() {
        setIsLoading(true);
        const shareFilePromises = selectedFileIds.map(fileId => {
            return Axios.post(`${secondServerAPI}/rest/api/v1/ufc/shareFile`, {
                toUserEmail: email,
                fileId,
                fromUserId: auth.User_id,
            });
        });

        Promise.all(shareFilePromises)
            .then(responses => {
                const allSuccessful = responses.every(response => response.status === 200);
                if (allSuccessful) {
                    console.log("All files shared successfully");
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("File shared successfully");
                } else {
                    const failedShares = responses.filter(response => response.status !== 200);
                    console.log(`${failedShares.length} files failed to share.`);
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("Failed to share some files");
                }
            })
            .catch(error => {
                console.error("Error sharing files:", error);
                setIsLoading(false);
                setIsDialogOpen(false);
                alert("Failed to share files");
            });
    }

    function handleDownloadSelectedFiles() {
        if (selectedFileIds.length === 0) {
            alert('Please select at least one file to download.');
            return;
        }

        setIsLoading(true);
        Axios.get(`https://lbidsufc8080.asets.io/download`, {
            headers: {
                'user-id' : auth.User_id,
                'fileId': selectedFileIds.join(',')
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`File Download Progress: ${percentCompleted}%`);
                setDownloadProgress(percentCompleted);
            }
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'files.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setIsLoading(false);
                setDownloadProgress(0);
            })
            .catch(error => {
                console.error('Error downloading files:', error);
                setIsLoading(false);
                setDownloadProgress(0);
            });
    }

    function handleFilterChange(event: ChangeEvent<HTMLSelectElement>) {
        setFileTypeFilter(event.target.value);
    }

    function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
        setSearchQuery(event.target.value);
    }

    function handleFilterClick() {
        setIsFilter(!isFilter);
    }

    const filteredFiles = files.filter(file => {
        const matchesType = fileTypeFilter === "" || file.type === fileTypeFilter;
        const matchesSearch = file.name.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesType && matchesSearch;
    });

    const filteredSharedFiles = sharedFiles.filter(file => {
        const matchesType = fileTypeFilter === "" || file.type === fileTypeFilter;
        const matchesSearch = file.name.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesType && matchesSearch;
    });

    const filesToShow = filteredFiles.slice((currentPage - 1) * 20, currentPage * 20);
    const sharedFilesToShow = filteredSharedFiles.slice((currentPage - 1) * 20, currentPage * 20);

    const totalPages = Math.ceil((activeTab === "myFiles" ? filteredFiles.length : filteredSharedFiles.length) / 20);

    return (
        <div className="primary-file-upload-container">
            <div className="primary-file-handler-container">
                <div className="file-handler">
                    <h1 style={{ color: "#2c3e50" }}>File Manager</h1>
                </div>
                <div className="file-controller">
                    <div className="left-controls">
                        <Tooltip position="bottom" content={"Grid View"}>
                            <Button
                                icon="grid-view"
                                onClick={() => setView("grid")}
                                active={view === "grid"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"List View"}>
                            <Button
                                icon="list-columns"
                                onClick={() => setView("list")}
                                active={view === "list"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Upload Files"}>
                            <Button
                                icon="cloud-upload"
                                text="Upload"
                                className="bp3-intent-primary"
                                onClick={triggerFileInput}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"View Personal Files"}>
                            <Button
                                icon="person"
                                text="my files"
                                onClick={() => setActiveTab("myFiles")}
                                active={activeTab === "myFiles"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"View Files Shared with you"}>
                            <Button
                                icon="folder-shared"
                                text="shared-file"
                                onClick={() => setActiveTab("sharedFiles")}
                                active={activeTab === "sharedFiles"}
                            />
                        </Tooltip>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".e57,.obj,.stl,.ply,.fbx,.3ds,.gltf,.glb"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                            multiple
                        />
                    </div>
                    <div className="right-controls">
                        <InputGroup
                            id="fileSearch"
                            name="fileSearch"
                            placeholder="Search"
                            leftIcon="search"
                            className="input-field"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <Tooltip position="bottom" content={"Select All Files"}>
                            <Checkbox
                                style={{paddingTop: "10px"}}
                                label="Select All"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleSelectAll(e.target.checked)}
                            />
                        </Tooltip>
                        {isFilter && (
                            <select onChange={handleFilterChange} value={fileTypeFilter}>
                                <option value="">All</option>
                                {supported3DFormats.map(format => (
                                    <option key={format} value={format}>{format.toUpperCase()}</option>
                                ))}
                            </select>
                        )}
                        <Tooltip position="bottom" content={"Filter Files"}>
                            <Button icon="filter" onClick={handleFilterClick} />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Download Files"}>
                            <Button className="bp3-intent-success" icon="download" onClick={handleDownloadSelectedFiles} />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Share Files"}>
                            <Button className="bp3-intent-warning" icon="document-share" onClick={() => setIsDialogOpen(true)} />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Delete Files"}>
                            <Button className="bp3-intent-danger" icon="delete" onClick={handleDeleteFile} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="progress-container">
                    <ProgressBar value={uploadProgress / 100} intent="primary" />
                    {/* <ProgressBar value={downloadProgress / 100} intent="success" /> */}
                </div>
            )}
            <div className="file-viewer">
                <div className={`file-list ${view === "grid" ? "grid-view" : "list-view"}`}>
                    {activeTab === "myFiles" && filesToShow.map(file => (
                        <div key={file.id} className="file-item">
                            <Icon icon="document" />
                            <div className="file-info">
                                <h3>{file.name}</h3>
                                <p>{file.type} - {file.size} MB</p>
                            </div>
                            <Checkbox
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleCheckboxChange(file.id, e.target.checked)}
                                checked={selectedFileIds.includes(file.id)}
                            />
                        </div>
                    ))}
                    {activeTab === "sharedFiles" && sharedFilesToShow.length > 0 ? (
                        sharedFilesToShow.map(file => (
                            <div key={file.id} className="file-item">
                                <Icon icon="document" />
                                <div className="file-info">
                                    <h3>{file.name}</h3>
                                    <p>{file.type} - {file.size} MB</p>
                                </div>
                                <Checkbox
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleCheckboxChange(file.id, e.target.checked)}
                                    checked={selectedFileIds.includes(file.id)}
                                />
                                <Button text="Add to My Files" onClick={() => handleAddtoMyFile(file.id)} />
                            </div>
                        ))
                    ) : null}
                    {activeTab === "sharedFiles" && sharedFilesToShow.length === 0 ? (
                        <div className="no-files-message">
                            No files shared with you
                        </div>
                    ) : null}
                    {activeTab === "myFiles" && filesToShow.length === 0 ? (
                        <div className="no-files-message">
                            No files available
                        </div>
                    ) : null}
                </div>
                <div className="file-pagination">
                    <Button icon="chevron-left" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
                    <span>{currentPage} / {totalPages}</span>
                    <Button icon="chevron-right" disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
                </div>
            </div>
            <Dialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title="Share File"
            >
                <div className="bp3-dialog-body">
                    <FormGroup
                        label="Enter the email to share the file:"
                        labelFor="email-input"
                    >
                        <InputGroup
                            id="email-input"
                            placeholder="Email"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                </div>
                <div className="bp3-dialog-footer">
                    <Button text="Cancel" onClick={() => setIsDialogOpen(false)} />
                    <Button
                        text="OK"
                        intent="primary"
                        onClick={handleShareFile}
                    />
                </div>
            </Dialog>
            {isLoading && (
                <div className="loading-overlay">
                    <Spinner />
                </div>
            )}
        </div>
    );
}
