import React, { useEffect, useState } from 'react';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Camera, Object3D, Scene } from 'three';
import { ButtonGroup, Button, Icon } from '@blueprintjs/core';

interface TransformControlsComponentProps {
  camera: Camera;
  scene: Scene;
  canvas: HTMLCanvasElement;
  controls: OrbitControls | null;
  item: Object3D;
  onClose: () => void;
  onDelete: () => void;  // New prop for delete action
}

const TransformControlsComponent: React.FC<TransformControlsComponentProps> = ({
  camera,
  scene,
  canvas,
  controls,
  item,
  onClose,
  onDelete,  // New prop for delete action
}) => {
  const [transformControls, setTransformControls] = useState<TransformControls | null>(null);
  const [mode, setMode] = useState<'translate' | 'rotate' | 'scale'>('translate');

  useEffect(() => {
    const transformControls = new TransformControls(camera, canvas);
    transformControls.attach(item);
    scene.add(transformControls);

    const onChange = () => {
      controls && controls.update();
    };

    const onDraggingChanged = (event: any) => {
      controls && (controls.enabled = !event.value);
    };

    transformControls.addEventListener('change', onChange);
    transformControls.addEventListener('dragging-changed', onDraggingChanged);

    setTransformControls(transformControls);

    return () => {
      transformControls.removeEventListener('change', onChange);
      transformControls.removeEventListener('dragging-changed', onDraggingChanged);
      scene.remove(transformControls);
    };
  }, [camera, canvas, controls, item, scene]);

  const handleModeChange = (newMode: 'translate' | 'rotate' | 'scale') => {
    setMode(newMode);
    transformControls && transformControls.setMode(newMode);
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <ButtonGroup vertical>
        <Button
          icon="arrows-horizontal"
          active={mode === 'translate'}
          onClick={() => handleModeChange('translate')}
          style={{ marginBottom: '10px' }}
        />
        <Button
          icon="repeat"
          active={mode === 'rotate'}
          onClick={() => handleModeChange('rotate')}
          style={{ marginBottom: '10px' }}
        />
        <Button
          icon="zoom-to-fit"
          active={mode === 'scale'}
          onClick={() => handleModeChange('scale')}
          style={{ marginBottom: '10px' }}
        />
        <Button icon="trash" onClick={onDelete} /> 
        <Button icon="cross" onClick={onClose} />
      </ButtonGroup>
    </div>
  );
};

export default TransformControlsComponent;
