// PricingPlans.jsx
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import "./PricingPlans.css";

const stripePromise = loadStripe('pk_test_51O5w67GFmWd66UgV0ZrcRLdQic88kCCCVb27OWcLLKKpZFRI8Y1RdGExl4pOMfugq4CO0mjj9HMomMHVZwmI90aQ007i9jH6Sw');

const plans = [
  { name: 'Individual', price: 25, features: ['100 credits'] },
  { name: 'Standard', price: 35, features: ['250 credits'], highlight: true },
  { name: 'Business Pro', price: 65, features: ['500 credits'] },
  { name: 'Enterprise', contactUs: true, features: ['Team collaboration tools', 'Custom credit amounts', 'Expanded integrator access', 'Structural and piping analysis', 'Engineering generative AI', 'Project management suite'] },
];

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [email, setEmail] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowPaymentModal(true);
  };

  const handleCloseModal = () => {
    setShowPaymentModal(false);
    setIsSubmitting(false);
    setMessage('');
    setIsSuccess(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage('');
  
    if (!stripe || !elements) {
      setMessage('Stripe has not loaded yet. Please try again.');
      setIsSubmitting(false);
      return;
    }
  
    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);
  
      if (error) {
        setMessage(error.message);
        setIsSuccess(false);
        setIsSubmitting(false);
        return;
      }
  
      const amount = selectedPlan.price;
      const credits = parseInt(selectedPlan.features[0].match(/\d+/)[0], 10);
  
      const response = await fetch('https://idsufc.asets.io/rest/api/v1/ufc/makePayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          planId: selectedPlan.name,
          email,
          amount,
          credits, 
          stripeToken: token.id,
          additionalInfo: {
            ID_TAG: Math.random().toString(36).substr(2, 9)
          }
        }),
      });
  
      const session = await response.json();
  
      if (session.error) {
        setMessage(session.error.message);
        setIsSuccess(false);
      } else {
        setIsSuccess(true);
        setMessage(`Payment for the ${selectedPlan.name} plan was successful!`);
      }
    } catch (error) {
      setIsSuccess(false);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="pricing-page">
      <header className="pricing-header">
        <h1>Choose Your Monthly Converter Plan</h1>
      </header>

      <div className="pricing-container">
        {plans.map(plan => (
          <div key={plan.name} className={`plan ${plan.highlight ? 'highlight' : ''}`}>
            <div>
              <h2>{plan.name}</h2>
              <p>{plan.contactUs ? "Contact Us" : `CA$ ${plan.price}/month`}</p>
            </div>
            {plan.contactUs ? (
              <a href="https://www.asets.io/contact" className="contact-us">Chat with our team today</a>
            ) : (
              <Button className="buy-now" onClick={() => handlePlanSelect(plan)}>Buy Now</Button>
            )}
            <div>
              <p>Features include:</p>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <a href="#" className="learn-more">Learn more</a>
            </div>
          </div>
        ))}
      </div>

      {showPaymentModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Enter Payment Details</h2>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label>Email</label>
                <InputGroup
                  large
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Credit or Debit Card</label>
                <CardElement />
              </FormGroup>
              <FormGroup className="t-center">
                <Button
                  large
                  type="submit"
                  className="form-group-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner size={20} /> : "Submit Payment"}
                </Button>
              </FormGroup>
            </form>
            {message && <div className={`message ${isSuccess ? "success" : "error"}`}>{message}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

const PricingPlansWithStripe = () => (
  <Elements stripe={stripePromise}>
    <PricingPlans />
  </Elements>
);

export default PricingPlansWithStripe;
