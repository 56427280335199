import React, { useEffect, useState } from "react";
import { Button } from "@blueprintjs/core";
import { secondServerAPI } from "../../utils/agent";
import "./userProfile.css";
import Axios from "axios";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

interface UserProfile {
  userId: number;
  userEmail: string;
  userName: string;
  companyName: string;
  products: string;
}

export default function ShowUserProfile() {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [profilePhoto, setProfilePhoto] = useState<string>("https://www.w3schools.com/howto/img_avatar.png");

  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    Axios.get(`${secondServerAPI}rest/api/v1/dashboard/userInformation`, {
      headers: {
        "user-id": auth.User_id,
      }
    })
    .then(response => {
      if (response.status === 200) {
        setUserProfile(response.data);
        Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/profile_photo`, {
          headers: {
            "user-id": auth.User_id,
            // "user-id": 1,
          },
          responseType: 'blob'
        }).then(photoResponse => {
          if (photoResponse.status === 200) {
            const url = URL.createObjectURL(photoResponse.data);
            setProfilePhoto(url);
          }
        }).catch(error => {
          console.error("Error fetching profile photo:", error);
        });
      }
    })
    .catch(error => {
      console.error("Error fetching user information:", error);
    });
  }, []);

  function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if(file){
      if(file?.size > 1000000 ){
        alert("Image Size Greater than 1MB. Please try again")
        return;
      }
    }
    if (!file || !userProfile) return;

    const formData = new FormData();
    formData.append("profile_photo", file);

    Axios.post(`${secondServerAPI}rest/api/v1/dashboard/update/profile_photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "user-id": userProfile.userId
      }
    })
    .then(response => {
      if (response.status === 200) {
        const url = URL.createObjectURL(file);
        setProfilePhoto(url);
      }
    })
    .catch(error => {
      console.error("Error uploading profile photo:", error);
    });
  }

  return (
    <div className="user-profile-primary">
      <div className="user-profile-image">
        <img src={profilePhoto} alt="Avatar" className="avatar" />
        <input type="file" onChange={handleImageUpload} accept="image/jpeg, image/png, image/jpeg" style={{ display: 'none' }} id="upload-photo" />
        <Button onClick={() => document.getElementById('upload-photo')?.click()} className="mto-export-button" icon="upload" text="Update Profile Picture" />
        <p style={{
          fontSize: "10px"
          }}>
          *max image size 1Mb.
        </p>
      </div>
      <div className="user-profile-info">
        <div className="user-profile-field">
          <h5>Name:</h5>
          <p className="user-profile-name">{userProfile?.userName || "User Name"}</p>
        </div>
        <div className="user-profile-field">
          <h5>Email:</h5>
          <p className="user-profile-email">{userProfile?.userEmail || "Email"}</p>
        </div>
        <div className="user-profile-field">
          <h5>Company Name:</h5>
          <p className="user-profile-company">{userProfile?.companyName || "Company Name"}</p>
        </div>
        <div className="user-profile-field">
          <h5>Products:</h5>
          <div className="user-profile-products">
            {userProfile?.products.split(',').map((product, index) => (
              <div key={index} className="product-box">{product.trim()}</div>
            )) || "Products"}
          </div>
        </div>
      </div>
    </div>
  );
}
